import localForage from "localforage";
import { CronJob } from "cron"
import {perfil} from './../data/Data'

/**
 * Obtenemos las crecenciales y token necesarios para las llamadas
 */
let isRunning = false
let tokenUsu = ""
let apikey = ""
let secret = ""
localForage.getItem('evento').then(function(evento) {
    apikey = evento.apikey
    secret = evento.secret
}).catch(function(err) {
    console.error(err);
})
localForage.getItem('credenciales').then(function(value) {
    tokenUsu = value.id
}).catch(function(err) {
    console.error(err);
})
// Fin 
/**
 * * cronJobAlertas
 * Cada 15 min se va a hacer una consulta a las alertas pendientes si esta online para ver cuantas tenemos sin leer
 * para establecer un badge icon en alertas
 */
const cronJobAlertas = new CronJob("*/1 * * * *", async () => {  //new CronJob("*/5 * * * * ", async () => { -> se ejecutaría cada 5 minutos
    if(tokenUsu === "" || apikey === "") {
        localForage.getItem('evento').then(function(evento) {
            apikey = evento.apikey
            secret = evento.secret
        }).catch(function(err) {
            console.error(err);
        })
        localForage.getItem('credenciales').then(function(value) {
            tokenUsu = value.id
        }).catch(function(err) {
            console.error(err);
        })
    }
    if (!isRunning) {
        isRunning = true
        if (navigator.onLine) {
            perfil.getAlertas(tokenUsu, 'getMisAlertas', apikey, secret).then(response => {
                const alertasNoReaded = response.data.misAlertas.filter(alerta => alerta.leido === '0')
                localStorage.setItem('alertsNoReaded', alertasNoReaded.length);
            })
            .catch(err => console.warn(err))
        }
        isRunning = false
    }
})
cronJobAlertas.start()