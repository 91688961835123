import localForage from "localforage";
import { CronJob } from "cron"
import axios from "axios";

let isRunning = false
const job = new CronJob("* * * * * *", async () => {  //new CronJob("*/5 * * * * *", async () => { -> se ejecutaría cada 5 minutos
    if (!isRunning) {
      isRunning = true
      if (navigator.onLine) {
        const keys = await localForage.keys()
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]

          
          let a = key.split('-')
          if(a[0] === 'add') {
            try {
                const value = await localForage.getItem(key)
                value.map((item, i) => {
                  axios.post('https://hcp-auth-demo.proyectocelvision.net/api/webapp/', item)
                  .then(() => {console.info('ok')})
                })
                await localForage.removeItem(key)
                
              } catch (err) {
                  console.error(err)
              }
          }
          
          // if (key.startsWith("add")) {
          //   try {
          //     const value = await localForage.getItem(key)
          //     axios.post('https://*****', {
          //       mensaje:value
          //     })
          //     .then(() => console.info('ok'))
          //   } catch (err) {
          //       console.error(err)
          //   }
          // }
        }
      }
      isRunning = false
    }
  })
job.start()