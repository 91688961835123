import React, { useEffect, useState, Suspense, useContext, createContext } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import localForage from "localforage";
import DeviceOrientation, { Orientation } from 'react-screen-orientation'

import CacheBuster from './CacheBuster';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Pages
const Bienvenido = React.lazy(() => import('./components/Bienvenido'));
const InicioSesion = React.lazy(() => import('./components/InicioSesion'));
const RecordarPass = React.lazy(() => import('./components/RecordarPass'));
const Inicio = React.lazy(() => import('./components/Slider'));
const Perfil = React.lazy(() => import('./components/Perfil'));
const PViajes = React.lazy(() => import('./components/perfil/Viaje'));
const PAlojamiento = React.lazy(() => import('./components/perfil/Alojamiento'));
const PNotas = React.lazy(() => import('./components/perfil/Notas'));
const PNotificaciones = React.lazy(() => import('./components/perfil/Notificaciones'));
const Agenda = React.lazy(() => import('./components/Agenda'));
const Voting = React.lazy(() => import('./components/Voting'));
const InfoLugar = React.lazy(() => import('./components/InfoLugar'));
const ITContigo = React.lazy(() => import('./components/ITContigo'));
const Alertas = React.lazy(() => import('./components/Alertas'));
const Multieventos = React.lazy(() => import('./components/Multievento'));
const Galeria = React.lazy(() => import('./components/Galeria'));
const Autologin = React.lazy(() => import('./components/Autologin'));
const CambioPass = React.lazy(() => import('./components/CambioPass'));
const Videoblog = React.lazy(() => import('./components/Videoblog'));
const FondoDocumental = React.lazy(() => import('./components/FondoDocumental'));
const Contacto = React.lazy(() => import('./components/Contacto'));
const VisorPdf = React.lazy(() => import('./components/perfil/miViaje/Visor'))
const InstruccionesPWA = React.lazy(() => import('./components/popupinfoPWA/SeccionopupinfopwaComponent'))
const CodigoQR = React.lazy(() => import('./components/CodigoQR'));
const pathroute = window.location.pathname;

//const style = 'font-weight: bold; font-size: 40px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)';
//console.log('%c BMS PWA EVENTOS by Smartbits', style);
// console.log(`MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWNK0KXWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMKdccclkNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWOl::::dXMMMWWWWMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWOl::::dXMWKxddONMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMWX0KNWMWOl::ccdXWXd:c:l0WMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMNOlcco0WWOl::c:dXWXdc:clOWMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMNxc::ckWWOl::c:dXWXdcc:lOWMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMNxc::ckWWOl::c:dXWXdcc:lOWMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMNxc::ckWWOl::c:dXWXdcc:lOWMMWNNWMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMNxc::ckWWOl::c:dXWXdcc:lOWWXkdoxKWMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMNxcc:ckWWOl::c:dXWXdcc:lOWWOcc:cxNMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMNxcc:ckWWOl::c:dXWXdc::lOWWkccccxNMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMNXKXWMMNxc::ckWWOl::c:dXWXdc::lOWWkc::cxNMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMXxlclONWXxcccckWWOl::ccdXWXdcc:lOWWkccccxNMMMMMMMMMMMMMM
// MMMMMMMMMMMMMW0lc::dXWXxc:cckWWOlccc:dXWXdcc:lOWWkccccxNMMMMMMMMMMMMMM
// MMMMMMMMMMMMMW0l::cdXWNxcccckWWOl:cccdXWXd:::lOWWkccccxNMMMMMMMMMMMMMM
// MMMMMMMMMMMMMW0l:ccdXWNxcc:cOWW0occccxXWXd:::lOWWkccccxNMMMMMMMMMMMMMM
// MMMMMMMMMMMMMW0lcc:dXWWKxodkXWMWKkkkOXWMNxc:cl0WWkccccxNMMMMMMMMMMMMMM
// MMMMMMMMMMMMMW0lcccdXMMMWNWWMMMMMMMMMMMMWXOkkKWMWOccccxNMMMMMMMMMMMMMM
// MMMMMMMMMMMMMW0l:ccdXMMMMMMMMMMMMMMMMMMMMMMMMMMMWKdcco0WMMMMMMMMMMMMMM
// MMMMMMMMMMMMMWKo::coKWMMMMMMMMMMMMMMMMMMMMMMMMMMMWNKKXWMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMXdc:clOWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMWOlcccdKWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMXxc:ccdKWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMWXdc::co0NWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMWXxlc:cld0NWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMN0dcc:ccok0NWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMWNOdlcc:ccokNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMWNKkdlc:cdXMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMWX0O0XWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
// MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM`)


function App() {
  const [key, setKey] = useState(false)
  const [aux, setAux] = useState(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  let tema = createTheme({
    palette: {
      primary: {
        main: '#7f207d' // MORADO
      },
      secondary: {
        main: '#44rfwe' // NONE
      },
      letra: {
        main: '#ffffff', //BLANCO
      },
      txtInformativo: {
        main: '#59ffb9' //VERDE
      },
      defaultBoton: {
        main: '#e0e0e0' //GRIS
      },
      bar: {
        main: '#262635', //NEGRO
        bottom: '#000', //NEGRO BARRA INFERIOR
        title: '#fff'
      },
      agendaBar: {
        bg: '#ffffff9b',
      },
      iconsBackground: {
        main: '#e0e0e0' // GRIS
      },
      iconsMenu: {
        main: '#ffffffbb', //BLANCO OPACIDAD
        bottom: '#fff' // BLANCO SIN OPCIDAD BARRA INFERIOR ICONOS
      },
      divisor: {
        main: '#ffffff80' //DIVIDER
      }
    }
  });
  useEffect(() => {
    localForage.getItem('credenciales').then(function (val) {
      if (val) {
        setKey(true);
      } else {
        setKey(false);
      }
    }).catch(function (err) {
      setKey(false);
    })
  }, [])

  function PrivateRoute({ children, ...rest }) {
    let miKey = true
    localForage.getItem('credenciales').then(function (val) {
      if (val) {
        //console.log('true')
        miKey = true;
      } else {
        //console.log('false')
        miKey = false;
      }
    }).catch(function (err) {
      //console.log('false')
      miKey = false;
    })
    return (
      <Route
        {...rest}
        render={() => {
          return miKey === true
            ? children
            : <Redirect to={{
              pathname: '/bienvenido'
            }} />
        }
        }
      />
    );
  }
  function PublicRoute({ children, ...rest }) {
    let miKey = false
    localForage.getItem('credenciales').then(function (val) {
      if (val) {
        //console.log('true')
        miKey = true;
      } else {
        //console.log('false')
        miKey = false;
      }
    }).catch(function (err) {
      //console.log('false')
      miKey = false;
    })
    return (
      <Route
        {...rest}
        render={() => {
          return miKey === false
            ? children
            : <Redirect to={{
              pathname: '/multieventos'
            }} />
        }
        }
      />
    );
  }

  function callBack() {
    localForage.getItem('credenciales').then(function (val) {
      if (val) {
        //console.log('true')

      } else {
        setKey(false)
        window.location.href = '/'
      }
    }).catch(function (err) {
      console.log('false')

    })
  }

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
      <ThemeProvider theme={tema}>
        {aux ?
        <DeviceOrientation className={'device'} lockOrientation={'portrait'}>
          <Orientation className={'ori'} orientation='portrait' alwaysRender={false}>
            <Router >
              <React.Suspense fallback={loading}>
                <Switch>
                  {/* {!compruebaKey('bbbbbb') ?
                <Route exact path={pathroute !== '/iniciosesion' && pathroute !== '/recordar' && pathroute !== '/autologin' && pathroute !== '/cambiar-password' ? pathroute : '/inicio'}>
                    <Redirect to="/" />
                </Route>
              :
                <Route exact path={pathroute === '/iniciosesion' || pathroute === '/recordar' || pathroute === '/autologin' || pathroute === '/cambiar-password' || pathroute === '/bienvenido' ? pathroute : '/autologin'}>
                    <Redirect to="/inicio" />
                </Route>
              } */}
                  <PublicRoute exact path="/iniciosesion" name="Inicio sesión" ><InicioSesion /></PublicRoute>
                  <PublicRoute exact path="/login" name="Inicio sesión" ><InicioSesion /></PublicRoute>
                  <PublicRoute exact path="/bienvenido" name="Bienvenido" ><Bienvenido /></PublicRoute>
                  <PublicRoute exact path="/recordar" name="Recordar Contraseña"><RecordarPass /></PublicRoute>
                  <PrivateRoute path="/inicio" name="Inicio"><Inicio callBack={callBack} /></PrivateRoute>
                  <PrivateRoute path="/perfil" name="Perfil"><Perfil callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/mis-viajes" name="Mis Viajes"><PViajes callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/alojamientos" name="Alojamientos"><PAlojamiento callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/mis-notas" name="Mis Notas"><PNotas callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/notificaciones" name="Notificaciones"><PNotificaciones callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/agenda" name="Agenda"><Agenda callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/voting" name="Voting"><Voting callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/informacion-lugar" name="Información luegar"><InfoLugar callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/it-contigo" name="IT Contigo" ><ITContigo callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/alertas" name="Alertas"><Alertas callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/multieventos" name="Multieventos" ><Multieventos callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/galeria" name="Galeria"><Galeria callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/codigo-qr" name="Codigo QR"><CodigoQR callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/videoblog" name="Videoblog"><Videoblog callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/fondo-documental" name="Fondo documental"><FondoDocumental callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/contacto" name="Contacto"><Contacto callBack={callBack} /></PrivateRoute>
                  <PrivateRoute exact path="/visorPdf" name="visorPdf"><VisorPdf callBack={callBack} /></PrivateRoute>
                  <PublicRoute exact path="/autologin" name="Autologin"><Autologin /></PublicRoute>
                  <PublicRoute exact path="/cambiar-password" name="Cambiar Contraseña" ><CambioPass /></PublicRoute>
                  <PublicRoute exact path="/instrucciones" name="Cambiar Contraseña" ><InstruccionesPWA /></PublicRoute>
                  <PublicRoute path="*" name="Bienvenido" ><Bienvenido /></PublicRoute>
                </Switch>
              </React.Suspense>
            </Router>
          </Orientation>
          <Orientation className={'ori'} orientation='landscape' alwaysRender={false}>
            <Box style={{ background: `url(${process.env.PUBLIC_URL + '/fondo.jpg'})`, backgroundSize: 'cover', backgroundPosition: 'center', height:'100vh', width:'100vw', textAlign:'center'}}>
              <Box style={{color:'#fff', fontSize:'20px', display:'inline-block', padding:'20px 25px', backgroundColor:'#ffffff33', marginTop: '40vh'}}>Coloque el dispositivo de forma vertical para poder continuar con su uso</Box>
            </Box>
          </Orientation>
        </DeviceOrientation>
        : 
          <Router >
            <React.Suspense fallback={loading}>
              <Switch>
                {/* {!compruebaKey('bbbbbb') ?
              <Route exact path={pathroute !== '/iniciosesion' && pathroute !== '/recordar' && pathroute !== '/autologin' && pathroute !== '/cambiar-password' ? pathroute : '/inicio'}>
                  <Redirect to="/" />
              </Route>
            :
              <Route exact path={pathroute === '/iniciosesion' || pathroute === '/recordar' || pathroute === '/autologin' || pathroute === '/cambiar-password' || pathroute === '/bienvenido' ? pathroute : '/autologin'}>
                  <Redirect to="/inicio" />
              </Route>
            } */}
                <PublicRoute exact path="/iniciosesion" name="Inicio sesión" ><InicioSesion /></PublicRoute>
                <PublicRoute exact path="/login" name="Inicio sesión" ><InicioSesion /></PublicRoute>
                <PublicRoute exact path="/bienvenido" name="Bienvenido" ><Bienvenido /></PublicRoute>
                <PublicRoute exact path="/recordar" name="Recordar Contraseña"><RecordarPass /></PublicRoute>
                <PrivateRoute path="/inicio" name="Inicio"><Inicio callBack={callBack} /></PrivateRoute>
                <PrivateRoute path="/perfil" name="Perfil"><Perfil callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/mis-viajes" name="Mis Viajes"><PViajes callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/alojamientos" name="Alojamientos"><PAlojamiento callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/mis-notas" name="Mis Notas"><PNotas callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/notificaciones" name="Notificaciones"><PNotificaciones callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/agenda" name="Agenda"><Agenda callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/voting" name="Voting"><Voting callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/informacion-lugar" name="Información luegar"><InfoLugar callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/it-contigo" name="IT Contigo" ><ITContigo callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/alertas" name="Alertas"><Alertas callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/multieventos" name="Multieventos" ><Multieventos callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/galeria" name="Galeria"><Galeria callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/codigo-qr" name="Codigo QR"><CodigoQR callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/videoblog" name="Videoblog"><Videoblog callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/fondo-documental" name="Fondo documental"><FondoDocumental callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/contacto" name="Contacto"><Contacto callBack={callBack} /></PrivateRoute>
                <PrivateRoute exact path="/visorPdf" name="visorPdf"><VisorPdf callBack={callBack} /></PrivateRoute>
                <PublicRoute exact path="/autologin" name="Autologin"><Autologin /></PublicRoute>
                <PublicRoute exact path="/cambiar-password" name="Cambiar Contraseña" ><CambioPass /></PublicRoute>
                <PublicRoute exact path="/instrucciones" name="Cambiar Contraseña" ><InstruccionesPWA /></PublicRoute>
                <PublicRoute exact path="/" name="Bienvenido" ><Bienvenido /></PublicRoute>
                <PublicRoute  path="*" name="Bienvenido" ><Bienvenido /></PublicRoute>
              </Switch>
            </React.Suspense>
          </Router>
        }
      </ThemeProvider>
          )
      }}
    </CacheBuster>

  );
}
export default App;