import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import "./syncro/Cron"
import "./syncro/CronAlertas"

//import "./syncro/actualizaComunicados"

ReactDOM.render(
  <BrowserRouter>
    
      <App />
    
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
//serviceWorkerRegistration.register();
serviceWorker.register();